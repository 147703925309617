import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import Index from 'components/index-container'

const EnIndex = ({ data }) => <Index data={data} cult='en' />

EnIndex.propTypes = { data: PropTypes.any }

export const query = graphql`
  {
    markdownRemark(frontmatter: { path: { eq: "/en" } }) {
      htmlAst
      html
      frontmatter {
        title
        path
        lang
      }
    }
  }
`

export default EnIndex
