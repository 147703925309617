import PropTypes from 'prop-types'
import React from 'react'

import './Dots.scss'

const Dots = ({ slides, slide, setSlide }) => (
  <div className='autoslider-dots'>
    {slides.map((_, index) => (
      <div
        key={index}
        onClick={() => setSlide(index)}
        className={`autoslider-dot ${
          index === slide ? 'autoslider-dot-active' : 'autoslider-dot-noactive'
        }`}
      />
    ))}
  </div>
)

export default Dots

Dots.propTypes = {
  slides: PropTypes.array,
  slide: PropTypes.number,
  setSlide: PropTypes.func
}
