import React from 'react'
import RehypeReact from 'rehype-react'

import Slider from './Slider/Slider'
import ContactForm from './ContactForm/ContactForm'

export default new RehypeReact({
  createElement: React.createElement,
  components: {
    slider: Slider,
    contactform: ContactForm
  }
}).Compiler
