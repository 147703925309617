import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'

import './SliderImage.scss'

const SliderImage = ({ src, alt }) => (
  <Img className='autoslider-image' fluid={src} alt={alt} />
)

export default SliderImage

SliderImage.propTypes = {
  link: PropTypes.string,
  src: PropTypes.object,
  alt: PropTypes.string
}
