import { StaticQuery, graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { useSwipeable } from 'react-swipeable'

import Dots from './Dots'
import SliderImage from './SliderImage'

import './Slider.scss'

// eslint-disable-next-line sonarjs/cognitive-complexity
const Slider = ({ slides }) => {
  const [curSlide, setCurSlide] = useState(0)

  const curSlides = JSON.parse(slides)

  useEffect(() => {
    let interval = setInterval(() => {
      setCurSlide(curSlide + 1 > curSlides.length - 1 ? 0 : curSlide + 1)
    }, 5000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  })

  const swipeHadlers = useSwipeable({
    onSwipedLeft:
      curSlide + 1 > curSlides.length - 1
        ? () => {
            setCurSlide(0)
          }
        : () => setCurSlide(curSlide + 1),
    onSwipedRight:
      curSlide - 1 < 0
        ? () => setCurSlide(curSlides.length - 1)
        : () => setCurSlide(curSlide - 1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: false
  })

  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { absolutePath: { regex: "/slides/" } }) {
            edges {
              node {
                name
                childImageSharp {
                  fluid(maxWidth: 380, maxHeight: 410, quality: 100) {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
      `}
      render={slidesData => {
        const { allFile } = slidesData
        const { edges } = allFile
        let thisSlides =
          edges.length &&
          edges.reduce(
            (
              slides,
              {
                node: {
                  name,
                  childImageSharp: { fluid }
                }
              }
            ) => {
              curSlides.map(({ image, link, alt }) => {
                if (name === image) {
                  slides.push({ link, fluid, alt })
                }
              })
              return slides
            },
            []
          )

        return (
          <div {...swipeHadlers} className='autoslider'>
            <div className='autoslider-wrapper'>
              {thisSlides.map(({ link, fluid, alt }, index) => (
                <div
                  key={index}
                  className={`autoslider-container ${
                    index === curSlide
                      ? 'autoslider-container-active'
                      : 'autoslider-container-noactive'
                  }`}
                >
                  {link ? (
                    <Link to={link} className='slide-container'>
                      <SliderImage src={fluid} alt={alt} />
                    </Link>
                  ) : (
                    <div className='slide-container'>
                      <SliderImage src={fluid} alt={alt} />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <Dots slides={thisSlides} slide={curSlide} setSlide={setCurSlide} />
          </div>
        )
      }}
    />
  )
}

export default Slider

Slider.propTypes = {
  slides: PropTypes.string
}
